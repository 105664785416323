import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { TextField, Button, Box } from "@mui/material";
import userData from "../services/userData";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Loading } from "../pages/InterviewRoom";
import { useNavigate } from "react-router";

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const tempPuchaseLinks = [
  "https://store.payproglobal.com/checkout?products[1][id]=105109",
  "https://store.payproglobal.com/checkout?products[1][id]=105111",
  "https://store.payproglobal.com/checkout?products[1][id]=102407",
  "https://store.payproglobal.com/checkout?products[1][id]=102661",
];

const plans = [
  {
    validity: 1,
  },
  {
    validity: 3,
  },
  {
    validity: 6,
  },
  {
    validity: 12,
  },
];

function PricingCard({ plan, buyHandler, buying, index }) {
  const { validity } = plan;

  return (
    <div className=" bg-white flex flex-col items-center gap-5 p-5 min-w-[250px] primary-shadow rounded-xl hover:scale-[1.02] transition-all duration-500">
      <div className="flex flex-col gap-5">
        <p className="font-bold text-lg text-center">
          {validity + " Month" + (validity > 1 ? "s" : "")}
        </p>
        {/* <p className="text-gray-500 font-semibold flex justify-between">
          <span className="mr-2">Original price:</span>
          <span>
            {local ? "₹ " : "€ "}
            {originalPrice || price}
          </span>
        </p> */}
        {/* {price && originalPrice && price < originalPrice && (
          <p className="font-semibold text-green-500 flex justify-between">
            <span className="mr-2">Discount:</span>
            <span>
              {local ? "₹ " : "€ "}
              {originalPrice - price}
            </span>
          </p>
        )} */}
        {/* <p className="font-bold text-3xl text-center">
          <span className="text-secondary font-semibold text-base mr-2">
            Total:{" "}
          </span>

          <span className="mr-1">
            {local ? "₹ " : "€ "}
            {price}
          </span>
          <span className="text-secondary text-sm">{` + ${
            local ? "GST" : "VAT"
          } `}</span>
        </p> */}
      </div>
      {buying !== plan.id && (
        <button
          onClick={() => buyHandler(index)}
          className="w-full px-3 py-2 rounded-md bg-secondary hover:bg-secondary/70 transition-all duration-300 text-white font-semibold"
        >
          Select
        </button>
      )}
      {buying === plan.id && (
        <div className="flex justify-center items-center gap-2">
          <Loading />
          <p>Processing your request</p>
        </div>
      )}
    </div>
  );
}

function Pricing() {
  const [buying, setBuying] = useState(null);

  const [referralCodeValidated, setReferralCodeValidated] = useState(false);

  const buyHandler = async (index) => {
    setBuying(index);
    console.log("Purchase request for plan id: ", index);
    // Handle purchase
    try {
      const token = Cookies.get("token");
      const resId = await userData.getId(token);
      const userId = resId.data.id;
      const res = await userData.getSubscriptionDetails({ token });
      const existingPlan = {
        subscription: res.data.subscription,
        expiry: res.data.expiry,
      };
      console.log("User plan = ", existingPlan);
      if (existingPlan.subscription) {
        toast.error(
          `You already have an active plan, current plan will expire on: ${existingPlan.expiry}`
        );
        return;
      }
      window.location.href = `${tempPuchaseLinks[index]}&x-userid=${userId}`;
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message || "Failed to fetch payment url");
    } finally {
      setBuying(null);
    }
  };
  const [referralCode, setReferralCode] = useState("");

  const [loadingPlan, setLoadingPlan] = useState(false);
  const [activatingPlan, setActivatingPlan] = useState(false);

  const [referralPlan, setReferralPlan] = useState(null);

  const [step, setStep] = useState(1);

  const handleChange = (event) => {
    setReferralCode(event.target.value);
  };

  useEffect(() => {
    handleSubmit({ target: { id: "no-code-plans" } });
  }, []);

  const handleSubmit = async (event) => {
    const isReferralCodeApplied = event.target.id !== "no-code-plans";
    if (event && event.preventDefault) event.preventDefault();
    try {
      setLoadingPlan(true);
      const token = Cookies.get("token");
      const data = {
        token,
        code: isReferralCodeApplied ? referralCode : "",
      };
      const res = await userData.fetchPlans(data);
      if (res.data?.planType === "trial") {
        const { expiry, validity } = res.data;
        setReferralPlan({ expiry, validity });
        toast.success("You got a free trial plan.");
        setStep(2);
        return;
      }
      if (isReferralCodeApplied) {
        setReferralCodeValidated(true);
        toast.success("Referral code validated successfully");
      }
      console.log("Fetched plans = ", res);
      // setStep(3);
    } catch (err) {
      console.log(err);
      if (isReferralCodeApplied)
        toast.error(
          err.response?.data?.message || "Failed to verify referral code."
        );
    } finally {
      setLoadingPlan(false);
    }
  };

  const navigate = useNavigate();

  const activateTrialPlan = async function () {
    try {
      setActivatingPlan(true);
      const token = Cookies.get("token");
      const data = {
        token,
        code: referralCode,
      };
      const res = await userData.useReferralCode(data);
      if (res.data?.success) {
        toast.success(res.data?.message || "Trial plan activated successfully");
        setTimeout(() => navigate("/dashboard"), 2500);
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err.response?.data?.message || "Error activating subscription plan"
      );
    } finally {
      setActivatingPlan(false);
    }
  };

  return (
    <div>
      <div className="pricing-gradient relative py-10 flex flex-col items-center">
        <p className="text-2xl font-bold">Choose a plan</p>
        <p className="">Get access to all the exciting features.</p>
      </div>
      {step === 1 && (
        <div className="pb-10">
          <div className="flex justify-center gap-6 -translate-y-[10%]">
            {plans &&
              Array.isArray(plans) &&
              plans.map((plan, index) => {
                plan.purchaseLink = tempPuchaseLinks[index];
                return (
                  <PricingCard
                    key={index}
                    plan={plans[index]}
                    buyHandler={buyHandler}
                    buying={buying}
                    index={index}
                  />
                );
              })}

            {!plans && (
              <div className="flex gap-2 items-center h-[80vh]">
                <Loading />
                <p>Fetching plans..</p>
              </div>
            )}
          </div>
          {plans && (
            <div className="rounded-md primary-shadow my-5 p-10">
              <p className="text-base text-secondary-text">
                <strong className="text-secondary">Remark: </strong>
                {`Compare our flexible subscription options to find the best value for you. Each longer-term plan offers progressively lower monthly rates – our 12-month plan provides the lowest monthly rate compared to our 6-month plan, which in turn is more economical than shorter subscriptions. Select any plan to view detailed pricing.`}
                <br />
                <br />
                <span className="font-semibold">{`To compare different plans, use your browser's back button to return to this page.`}</span>
              </p>
            </div>
          )}
        </div>
      )}
      {step === 2 && (
        <div className="flex justify-center mt-4">
          <div className="p-5 rounded-xl primary-shadow min-w-[250px] flex flex-col gap-2">
            <p className="text-lg font-bold">Trial plan</p>
            <p>Validity: {referralPlan.validity + " days"}</p>
            <p>Expiry: {referralPlan.expiry}</p>
            <Button
              onClick={activateTrialPlan}
              variant="contained"
              color="success"
            >
              Activate plan
            </Button>
          </div>
        </div>
      )}
      {step === 1 && (
        <div className="flex justify-end p-10 gap-20 items-center">
          <div className="mt-5">
            <p className="font-bold text-xl mb-5 text-center">
              Team / Affiliate Access
            </p>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                width: 300,
                margin: "0 auto",
                padding: 3,
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: "white",
              }}
            >
              <TextField
                label="Enter code"
                variant="outlined"
                value={referralCode}
                onChange={handleChange}
                fullWidth
                required
              />
              {!loadingPlan && (
                <button
                  type="submit"
                  fullWidth
                  className="w-full px-3 py-2 rounded-md bg-secondary hover:bg-secondary/70 transition-all duration-300 text-white font-semibold"
                >
                  Submit
                </button>
              )}
              {loadingPlan && (
                <div className="flex justify-center items-center gap-2">
                  <Loading />
                  Verifying your referral code
                </div>
              )}
            </Box>
          </div>
        </div>
      )}
    </div>
  );
}

export default Pricing;
