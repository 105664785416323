import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="relative">
      <div className="absolute top-[0px] left-[48px] flex gap-6 items-center">
        <img src="/logo.png" height={50} width={50} />
        <div className="flex flex-col items-center justify-between custom-family translate-y-2">
          <a
            href="https://www.yuktii.com"
            target="_blank"
            className=" font-bold text-[#0070C0] flex text-4xl"
          >
            <p>Y</p>
            <p className=" text-[#FF671F]">U</p>
            <p>K</p>
            <p>T</p>
            <p>I</p>
            <p>I</p>
          </a>
          <div className="text-gray-600 font-bold">VENTURES</div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 px-12 mt-4 gap-7">
        <div className="flex flex-col gap-5 mt-16">
          <div className="">
            <h1 className="text-4xl font-bold">Welcome to PARINAAM</h1>
          </div>
          <div>
            <p className="text-secondary-text">
              Our application offers personalized career guidance to empower
              aspirants in making informed decisions, exploring opportunities,
              and achieving their personal and professional goals.
            </p>
          </div>
          <div className="flex gap-[45px] items-center">
            <div className="flex flex-col gap-2 items-center">
              <Link
                to="/login"
                className="border-2 border-secondary px-3 py-2 rounded-md transition-all duration-300 text-secondary hover:bg-secondary hover:text-white min-w-[100px] text-center translate-x-[25%]"
              >
                Login
              </Link>
              <div className="flex justify-end translate-x-[25%]">
                <Link
                  to="/register"
                  className="bg-secondary hover:bg-secondary/70 border-2 border-secondary transition-all text-white px-3 py-2 duration-300 rounded-md min-w-[100px] text-center"
                >
                  Sign Up
                </Link>
              </div>
              <a href="https://www.yuktii.com/parinaam" target="_blank">
                <button className="bg-secondary hover:bg-secondary/70 border-2 border-secondary transition-all text-white px-3 py-2 duration-300 rounded-md w-[100px] text-center mt-7 translate-x-[25%]">
                  <p className=" text-sm">PARINAAM overview</p>
                </button>
              </a>
            </div>
            <div>
              <iframe
                src="https://www.youtube.com/embed/b0-3nSDG52w?si=4FrKZ2bgoBcyFlIE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowFullScreen
                className="aspect-video min-h-[200px]"
              ></iframe>
            </div>
          </div>

          <p className="text-secondary-text mt-4">
            {
              "Parinaam: In Sanskrit the word परिणाम (Pariṇām) means transformation, change, results, and outcome. For those who are looking for a transformative career journey, PARINAAM will be your lifelong companion!"
            }
          </p>
        </div>
        <div className="rounded-xl hidden md:block overflow-hidden">
          <img src="/reached-top.jpg" />
        </div>
      </div>
    </div>
  );
}

export default Home;
